import React, { createContext, useContext } from 'react';

import { IBlackBackgroundCampaignConfiguration } from 'models/blackBackgroundCampaignConfiguration';
import { IDonationCampaign } from 'models/donationCampaign';
import { IMenuServer } from 'models/menu';
import { ITopBanner } from 'models/topBanner';

export const AppContext = createContext<{
  isServer: boolean;
  donationCampaigns: IDonationCampaign[];
  menuStructure: IMenuServer[];
  footerMenuStructure: IMenuServer[];
  paypalThreshold?: number;
  applepayEnabled?: string;
  googlepayEnabled?: string;
  paypalEnabled?: string;
  menu404Structure: IMenuServer[];
  xmasBanner?: ITopBanner;
  blackBackgroundCampaignConfiguration?: IBlackBackgroundCampaignConfiguration;
  featuredDonationCampaignSlug?: string;
  featuredGiftGivingCampaignSlug?: string;
  productListCampaignSuggestedItems?: string;
  facebookFoundationPage?: string;
  instagramFoundationUser?: string;
  twitterFoundationUser?: string;
  youtubeFoundationChannel?: string;
  festiveTreeSlug?: string;
  festiveInstagramPost?: string;
  displayBlackBackgroundCampaign: boolean;
  animationsS3Url?: string;
  thankYouPageFeaturedCampaignSlug?: string;
}>({
  isServer: false,
  donationCampaigns: [],
  menu404Structure: [],
  paypalThreshold: undefined,
  applepayEnabled: undefined,
  googlepayEnabled: undefined,
  paypalEnabled: undefined,
  menuStructure: [],
  // Configuration to swap the layout each day for a homepage
  blackBackgroundCampaignConfiguration: undefined,
  footerMenuStructure: [],
  xmasBanner: undefined,
  featuredDonationCampaignSlug: undefined,
  featuredGiftGivingCampaignSlug: undefined,
  productListCampaignSuggestedItems: undefined,
  facebookFoundationPage: undefined,
  instagramFoundationUser: undefined,
  twitterFoundationUser: undefined,
  youtubeFoundationChannel: undefined,
  festiveTreeSlug: undefined,
  festiveInstagramPost: undefined,
  displayBlackBackgroundCampaign: false,
  animationsS3Url: undefined,
  thankYouPageFeaturedCampaignSlug: undefined,
});

const AppContextProvider = ({
  serverInitialState,
  children,
}: React.PropsWithChildren<any>) => {
  return (
    <AppContext.Provider
      value={{
        menu404Structure: serverInitialState.menu404Structure,
        isServer: serverInitialState.isServer,
        donationCampaigns: serverInitialState.donationCampaigns,
        menuStructure: serverInitialState.menuStructure,
        blackBackgroundCampaignConfiguration:
          serverInitialState.blackBackgroundCampaignConfiguration,
        footerMenuStructure: serverInitialState.footerMenuStructure,
        xmasBanner: serverInitialState.xmasBanner,
        featuredDonationCampaignSlug:
          serverInitialState.featuredDonationCampaignSlug,
        thankYouPageFeaturedCampaignSlug:
          serverInitialState.thankYouPageFeaturedCampaignSlug,
        featuredGiftGivingCampaignSlug:
          serverInitialState.featuredGiftGivingCampaignSlug,
        productListCampaignSuggestedItems:
          serverInitialState.productListCampaignSuggestedItems,
        facebookFoundationPage: serverInitialState.facebookFoundationPage,
        instagramFoundationUser: serverInitialState.instagramFoundationUser,
        twitterFoundationUser: serverInitialState.twitterFoundationUser,
        youtubeFoundationChannel: serverInitialState.youtubeFoundationChannel,
        paypalThreshold: serverInitialState.paypalThreshold,
        applepayEnabled: serverInitialState.applepayEnabled,
        googlepayEnabled: serverInitialState.googlepayEnabled,
        paypalEnabled: serverInitialState.paypalEnabled,
        festiveTreeSlug: serverInitialState.festiveTreeSlug,
        festiveInstagramPost: serverInitialState.festiveInstagramPost,
        displayBlackBackgroundCampaign:
          serverInitialState.displayBlackBackgroundCampaign,
        animationsS3Url: serverInitialState.animationsS3Url,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  return useContext(AppContext);
};

export default AppContextProvider;
