import React, {
  createContext,
  useReducer,
  useCallback,
  useEffect,
  useContext,
} from 'react';

import * as Sentry from '@sentry/browser';
import API_KEY_NAME from 'constants/apiTokenValue';
import firebase from 'firebase/app';
import captureExceptionForClientSide from 'utils/captureExceptionForClientSide';
import processErrorWithGoogleTag from 'utils/processErrorWithGoogleTag';
import processGoogleTag from 'utils/processGoogleTag';

import { IError, Error } from 'models/error';
import { IUser } from 'models/user';
import { IUserProfile } from 'models/userProfile';
import { HttpRequestError } from 'services/httpRequestError';
import { fetcher } from 'services/httpRequestor';
import { UserService } from 'services/userService';

import cookie from '../utils/cookie';
import reducer, { initialState, AuthState, AuthTypes } from './state/authState';

interface IUserSignIn {
  email: string;
  password: string;
}
interface IUserResetPassword {
  email: string;
}

export interface IActions {
  loginAnonymous: () => Promise<firebase.auth.UserCredential | null>;
  login: (user: IUserSignIn) => Promise<boolean>;
  loginFacebook: () => Promise<boolean>;
  loginGoogle: () => Promise<boolean>;
  createUser: (user: IUserProfile, recaptchaToken: string) => Promise<boolean>;
  updateUser: (data: any, recaptchaToken: string) => Promise<boolean>;
  logout: () => Promise<void>;
  initialise: () => void;
  resetPassword: (user: IUserResetPassword) => Promise<boolean>;
  fetchAuthorisedUser: () => Promise<IUser | null>;
  linkAccount: (credentials: firebase.auth.AuthCredential) => Promise<void>;
  linkEmailAccount: (email: string, password: string) => Promise<void>;
  clearError: () => void;
}

const processLoginSuccessTag = (
  authenticationMethod: string,
  userUID: string,
) =>
  processGoogleTag({
    event: 'login',
    authenticationMethod,
    userUID,
  });

export const AuthContext = createContext<{
  state: AuthState;
  actions: IActions;
}>({
  state: initialState,
  actions: {
    fetchAuthorisedUser: () => Promise.reject(),
    loginAnonymous: () => Promise.reject(),
    createUser: () => Promise.reject(),
    loginFacebook: () => Promise.reject(),
    loginGoogle: () => Promise.reject(),
    login: () => Promise.reject(),
    logout: () => Promise.reject(),
    initialise: () => null,
    resetPassword: () => Promise.reject(),
    updateUser: () => Promise.reject(),
    linkAccount: () => Promise.reject(),
    linkEmailAccount: () => Promise.reject(),
    clearError: () => null,
  },
});

const AuthContextProvider = ({
  serverInitialState = {},
  children,
}: React.PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...serverInitialState,
  });

  const clearError = useCallback(async () => {
    dispatch({ type: AuthTypes.AUTH_CLEAR_ERROR });
  }, []);

  const parseErrorMessage = (error: IError) => {
    switch (error.code) {
      case 'auth/user-not-found':
        return (
          <>
            There has been an issue logging into your account. Please check your
            email and password. If you don't have an account{' '}
            <a href="/signin?createNewAccountTabActive=true">
              you can create one here
            </a>
          </>
        );
      default:
        return error.message;
    }
  };

  const dispatchAndCaptureAuthError = useCallback(
    (error: IError | HttpRequestError, captureContext?: any) => {
      const firebaseError = error as IError;
      captureExceptionForClientSide(error, captureContext);

      if (firebaseError?.code) {
        const { code } = firebaseError;
        const message = parseErrorMessage(firebaseError);

        dispatch({
          type: AuthTypes.AUTH_FAILURE,
          payload: { code, message } as IError,
        });
        return;
      }

      const { status, data } = error as HttpRequestError;
      if (status && data.error) {
        dispatch({
          type: AuthTypes.AUTH_FAILURE,
          payload: { code: status, message: data.error },
        });
        return;
      }

      dispatch({
        type: AuthTypes.AUTH_FAILURE,
        payload: { code: 400, message: 'unexpected error' },
      });
    },
    [dispatch],
  );

  const handleLoginError = useCallback(
    (error: IError | HttpRequestError, authenticationMethod) => {
      processErrorWithGoogleTag('login error', error.toString(), {
        authenticationMethod,
      });
      dispatchAndCaptureAuthError(error);
    },
    [dispatchAndCaptureAuthError],
  );

  const createFromSocial = useCallback(
    ({ user, credential, additionalUserInfo }, puid) => {
      const payload = {
        displayName: user.displayName,
        email: user.email,
        uid: user.uid,
        provider: credential?.providerId || additionalUserInfo?.providerId,
        puid,
      };

      const userService = new UserService(fetcher());

      // Save user information on Stack9
      return userService.social(payload).then(({ status, data }) => {
        // TODO: authentitcated
        if (status >= 400) {
          dispatchAndCaptureAuthError(new Error((data as any).message));
          return false;
        }

        return true;
      });
    },
    [dispatchAndCaptureAuthError],
  );

  const onUserAuthChanged = (user: firebase.User | null | undefined) => {
    if (user) {
      return firebase
        .auth()
        .currentUser?.getIdToken()
        .then(value => {
          cookie.set(API_KEY_NAME, value, { path: '/', sameSite: true });

          const userService = new UserService(fetcher());
          userService.getAuthorisedUser().then(({ data }) => {
            Sentry.setUser({ email: data?.email, id: data?.uid });

            dispatch({
              type: AuthTypes.LOGIN_SUCCESS,
              payload: data,
            });
          });
        });
    }

    cookie.remove(API_KEY_NAME);

    dispatch({ type: AuthTypes.SIGNOUT_SUCCESS });

    return undefined;
  };

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(onUserAuthChanged);
  }, []);

  const loginAnonymous = useCallback(async () => {
    try {
      return await firebase.auth().signInAnonymously();
      // callback onAuthStateChanged (above) will be called and api_token will be set.
    } catch (error: any) {
      handleLoginError(error, 'anonymous');
    }

    return null;
  }, [handleLoginError]);

  const loginFacebook = useCallback(async () => {
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      dispatch({
        type: AuthTypes.LOGIN,
      });
      return await firebase
        .auth()
        .signInWithPopup(provider)
        .then(authResponse => createFromSocial(authResponse, state.user?.uid));
    } catch (error: any) {
      handleLoginError(error, 'facebook');
    }

    return false;
  }, [handleLoginError, state, createFromSocial]);

  const loginGoogle = useCallback(async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      dispatch({
        type: AuthTypes.LOGIN,
      });
      return await firebase
        .auth()
        .signInWithPopup(provider)
        .then(authResponse => createFromSocial(authResponse, state.user?.uid));
    } catch (error: any) {
      handleLoginError(error, 'google');
    }

    return false;
  }, [handleLoginError, state, createFromSocial]);

  const login = useCallback(
    async (user: IUserSignIn) => {
      try {
        dispatch({
          type: AuthTypes.LOGIN,
        });
        const created = await firebase
          .auth()
          .signInWithEmailAndPassword(user.email, user.password)
          .then(authResponse =>
            createFromSocial(authResponse, state.user?.uid),
          );
        if (state.user?.uid) {
          processLoginSuccessTag('email', state.user?.uid);
        }
        return created;
      } catch (error: any) {
        processErrorWithGoogleTag('login', error.toString());
        dispatchAndCaptureAuthError(error);
      }

      return false;
    },
    [state.user?.uid, createFromSocial, dispatchAndCaptureAuthError],
  );

  const updateUser = async (toBeUpdatedProps: any, recaptchaToken: string) => {
    try {
      dispatch({
        type: AuthTypes.UPDATE_USER,
      });
      const userService = new UserService(fetcher());

      await userService.update(toBeUpdatedProps, recaptchaToken);

      dispatch({
        type: AuthTypes.UPDATE_USER_SUCCESS,
      });
      return true;
    } catch (error) {
      dispatch({
        type: AuthTypes.UPDATE_USER_FAILURE,
        payload: error,
      });
      return false;
    }
  };

  const fetchAuthorisedUser = useCallback(async () => {
    try {
      dispatch({
        type: AuthTypes.LOGIN,
      });

      const userService = new UserService(fetcher());
      const { data } = await userService.getAuthorisedUser();
      dispatch({
        type: AuthTypes.LOGIN_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error: any) {
      dispatchAndCaptureAuthError(error);
    }

    return null;
  }, [dispatchAndCaptureAuthError]);

  const createUser = useCallback(
    async (userProfile: IUserProfile, recaptchaToken: string) => {
      try {
        dispatch({
          type: AuthTypes.CREATE_USER,
        });

        const userService = new UserService(fetcher());
        await userService.create(userProfile, recaptchaToken);
        dispatch({
          type: AuthTypes.CREATE_USER_SUCCESS,
        });
        processGoogleTag({
          event: 'registration',
          authenticationMethod: 'email',
          userUID: state.user?.uid,
        });
        return true;
      } catch (error: any) {
        processErrorWithGoogleTag('registration', error.toString());
        dispatchAndCaptureAuthError(error);
      }

      return false;
    },
    [dispatchAndCaptureAuthError, state.user?.uid],
  );

  const logout = useCallback(async () => {
    await firebase.auth().signOut();
  }, []);

  const initialise = useCallback(() => {
    dispatch({ type: AuthTypes.AUTH_INITIALISED });
  }, [dispatch]);

  const resetPassword = useCallback(
    async (user: IUserResetPassword) => {
      clearError();

      const url = new URL(window.location.href);
      const baseUrl = `${url.protocol}//${url.host}`;

      try {
        await firebase.auth().sendPasswordResetEmail(user.email, {
          url: `${baseUrl}/signin`,
        });
        processGoogleTag({
          event: 'reset password',
          userUID: state?.user?.uid,
        });
        return true;
      } catch (error: any) {
        processErrorWithGoogleTag('reset password', error.toString());
        dispatchAndCaptureAuthError(error);
      }
      return false;
    },
    [clearError, state?.user?.uid, dispatchAndCaptureAuthError],
  );

  const linkAccount = useCallback(
    async (credentials: firebase.auth.AuthCredential): Promise<void> => {
      try {
        const response = await firebase
          .auth()
          .currentUser?.linkWithCredential(credentials);
        return onUserAuthChanged(response?.user);
      } catch (error: any) {
        dispatchAndCaptureAuthError(error, {
          extra: {
            currentUser: firebase.auth().currentUser,
          },
        });
        throw error;
      }
    },
    [dispatchAndCaptureAuthError],
  );

  const linkEmailAccount = useCallback(
    async (email: string, password: string) => {
      const credentials = firebase.auth.EmailAuthProvider.credential(
        email,
        password,
      );

      return linkAccount(credentials);
    },
    [linkAccount],
  );

  const actions: IActions = {
    loginAnonymous,
    login,
    loginFacebook,
    loginGoogle,
    createUser,
    logout,
    initialise,
    resetPassword,
    fetchAuthorisedUser,
    updateUser,
    linkAccount,
    linkEmailAccount,
    clearError,
  };

  return (
    <AuthContext.Provider value={{ state, actions }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): IActions & AuthState => {
  const { state, actions } = useContext(AuthContext);
  return Object.assign(actions, state);
};

export { useAuth };

export default AuthContextProvider;
