import processGoogleTag from './processGoogleTag';

const processErrorWithGoogleTag = (
  errorType: string,
  errorMessage: string,
  extra = {},
) =>
  processGoogleTag({
    event: 'error',
    errorType,
    errorMessage,
    ...extra,
  });

export default processErrorWithGoogleTag;
