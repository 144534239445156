import { captureException } from '@sentry/browser';
import { CaptureContext } from '@sentry/types';

import { HttpRequestError } from 'services/httpRequestError';

import getUserForSentryFromClientSide from './getUserForSentryFromClientSide';

const captureExceptionForClientSide = (
  exception: any,
  captureContext: CaptureContext | undefined = {},
) => {
  let error: any = exception;
  // Checking if it is firebaseError
  if (error.code && error.message) {
    error = new Error(`[${error.code}]: ${error.message}`);
  }
  if (exception instanceof HttpRequestError) {
    error = new Error(
      exception.data?.error ||
        exception.data?.message ||
        `An HttpRequestError ocurred. Received ${exception.status}`,
    );
  }
  return captureException(
    error instanceof Error
      ? new Error(`[Client-Side]: ${error.toString()}`)
      : new Error(`[Client-Side]: ${JSON.stringify(error)}`),
    {
      user: getUserForSentryFromClientSide(),
      ...captureContext,
    },
  );
};
export default captureExceptionForClientSide;
