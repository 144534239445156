import isArray from 'lodash/isArray';
// transform { bar: 'a', foo: 'b' } into 'bar=a&foo=b'
export function serialize(json: any) {
  return Object.keys(json)
    .filter(
      key =>
        (!isArray(json[key]) && json[key]) ||
        (isArray(json[key]) && json[key].length > 0),
    )
    .map(key => `${key}=${encodeURIComponent(json[key])}`)
    .join('&');
}

export function deserializeQuery(query: string): any {
  if (!query) {
    return null;
  }

  let q = decodeURI(query);
  if (q.indexOf('?') === 0) {
    q = q.slice(1);
  }
  const pairs = q.split('&');
  const result: any = {};
  pairs.forEach(pair => {
    const keyValue = pair.split('=');
    result[keyValue[0]] = decodeURIComponent(keyValue[1] || '');
  });
  return result;
}

export async function populateFirebaseReference<T, TItem>(
  collection: Array<any>,
  propertyName: string,
): Promise<T[]> {
  return Promise.all(
    collection.map(index =>
      collection[index][propertyName]
        .get()
        .then(({ id, data }) => ({ id, data: data() })),
    ),
  );
}

export function toFirebaseObject(data: any) {
  return JSON.parse(JSON.stringify(data));
}
