import { IUser } from 'models/user';
import { IUserProfile } from 'models/userProfile';

import HttpRequestor, { BaseResponse } from './httpRequestor';

export class UserService {
  constructor(private httpRequestor: HttpRequestor) {}

  public async create(
    user: IUserProfile,
    recaptchaToken: string,
  ): Promise<BaseResponse<{ uid: string; message: string }>> {
    return this.httpRequestor.post('/user/create', user, {
      headers: {
        'x-recaptcha-token': recaptchaToken,
      },
    });
  }

  public async update(
    user: IUserProfile,
    recaptchaToken: string,
  ): Promise<BaseResponse<{ uid: string; error: string }>> {
    return this.httpRequestor.post('/user/update', user, {
      headers: {
        'x-recaptcha-token': recaptchaToken,
      },
    });
  }

  public async login(
    email: string,
    password: string,
  ): Promise<BaseResponse<any>> {
    return this.httpRequestor.post('/user/login', {
      email,
      password,
    });
  }

  public async social(payload: any): Promise<BaseResponse<IUser>> {
    return this.httpRequestor.post('/user/social', payload);
  }

  public async checkStack9User(
    email: string,
  ): Promise<BaseResponse<{ email: string }>> {
    return this.httpRequestor.post('/user/check/', { email });
  }

  public async getAuthorisedUser(): Promise<BaseResponse<IUser>> {
    return this.httpRequestor.get(`/user/me`);
  }

  public async getUserProfile(): Promise<BaseResponse<IUserProfile>> {
    return this.httpRequestor.get(`/user/profile`);
  }
}
