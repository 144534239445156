import getConfig from 'next/config';

import API_KEY_NAME from 'constants/apiTokenValue';
import firebase from 'firebase';
import Cookies, { Cookie } from 'universal-cookie';

import cookie from '../utils/cookie';
import { serialize } from '../utils/objectTransformer';
import { HttpRequestError } from './httpRequestError';

const { publicRuntimeConfig } = getConfig();

export interface BaseResponse<T> {
  data: T;
  status: number;
}

class HttpRequestor {
  private readonly getAuthorizationBearerToken: () =>
    | { Authorization: string }
    | Record<string, unknown>;

  private readonly getAuthorizationApiKey: () =>
    | { Authorization: string }
    | Record<string, unknown>;

  private readonly getAuthorizationBasicToken: () =>
    | { Authorization: string }
    | Record<string, unknown>;

  private useFetch: () => any;

  private serialize: (body: any, contentType: string) => any;

  constructor(
    public fetch: any,
    public baseUrl: string,
    public universalCookies: Cookie,
  ) {
    const serializers: any = {
      'application/x-www-form-urlencoded': serialize,
      'application/json': JSON.stringify,
    };

    this.useFetch = () => fetch;

    this.getAuthorizationBearerToken = () => {
      const token = this.universalCookies.get('api_token');
      if (token) {
        return { Authorization: `Bearer ${token}`, 'API-Key': token };
      }
      return {};
    };

    this.getAuthorizationBasicToken = () => {
      const token = this.universalCookies.get('api_basic_token');
      if (token) {
        return { Authorization: `Basic ${token}` };
      }
      return {};
    };

    this.getAuthorizationApiKey = () => {
      const apiKey = this.universalCookies.get('api_key');
      if (apiKey) {
        return { 'Api-Key': apiKey };
      }
      return {};
    };

    this.serialize = (body: any, contentType: string) =>
      serializers[contentType](body);

    this.universalCookies = universalCookies;
    this.baseUrl = baseUrl;
  }

  get defaults() {
    return {
      method: 'POST',
      mode: this.baseUrl ? 'cors' : 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Vary: 'Origin, Access-Control-Request-Headers, Access-Control-Request-Method',
        ...this.getAuthorizationApiKey(),
        ...this.getAuthorizationBearerToken(),
        ...this.getAuthorizationBasicToken(),
      },
    };
  }

  public post<T>(
    url: string,
    body?: any,
    options?: any,
  ): Promise<BaseResponse<T>> {
    return this.execute<T>(url, {
      method: 'POST',
      body,
      ...options,
    });
  }

  public put<T>(
    url: string,
    body: any,
    options?: any,
  ): Promise<BaseResponse<T>> {
    return this.execute(url, {
      method: 'PUT',
      body,
      ...options,
    });
  }

  public get<T>(url: string, options?: any): Promise<BaseResponse<T>> {
    return this.execute<T>(url, {
      method: 'GET',
      ...options,
    });
  }

  public delete(url: string, options?: any): Promise<any> {
    return this.execute(url, {
      method: 'DELETE',
      ...options,
    });
  }

  public execute<T>(url: string, options?: any): Promise<BaseResponse<T>> {
    // query: get Parameters
    const { query, ...finalOptions } = options;
    const formattedURL = !query ? url : `${url}?${serialize(query)}`;
    const mergedOptions = {
      ...this.defaults,
      ...finalOptions,
      headers: {
        ...this.defaults.headers,
        ...options?.headers,
      },
    };

    const { body, headers } = mergedOptions;

    return this.useFetch()(`${this.baseUrl}${formattedURL}`, {
      ...mergedOptions,
      body: this.serialize(body, headers['Content-Type']),
    }).then(async (response: any) => {
      const contentType = response.headers.get('content-type');

      if (!contentType) {
        return { status: response.status };
      }
      if (process.browser && contentType.includes('application/octet-stream')) {
        return response.blob();
      }

      if (contentType.includes('application/pdf')) {
        return response.buffer();
      }
      if (
        response.status.toString().startsWith(4) ||
        response.status.toString().startsWith(5)
      ) {
        if (response.status === 401 && process.browser) {
          // window.location.href = '/?error=unauthorised';
          return { status: 401 };
        }
        if (response.status === 404 && !process.browser) {
          return { status: response.status };
        }
        throw new HttpRequestError(response.status, await response.json());
      }
      return {
        status: response.status,
        data: await response.json(),
      } as BaseResponse<T>;
    });
  }
}

if (process.browser) {
  const { fetch: originalFetch } = window;
  window.fetch = async (...args) => {
    const [resource, config] = args;
    // request interceptor here
    const response = await originalFetch(resource, config);

    if (response.status === 401) {
      // refresh token

      console.info('acquiring new token');

      const idToken = await firebase.auth().currentUser?.getIdToken();

      if (idToken) {
        cookie.set(API_KEY_NAME, idToken, { path: '/', sameSite: true });

        return originalFetch(resource, {
          ...config,
          headers: { ...config?.headers, Authorization: `Bearer ${idToken}` },
        });
      }

      cookie.remove(API_KEY_NAME);
    }

    return response;
  };
}

export const fetcher = (cookies?: string) =>
  new HttpRequestor(
    fetch,
    publicRuntimeConfig.serverUrl,
    new Cookies(cookies || (process.browser ? document.cookie : undefined)),
  );
export const fetcherStack9 = (apiKey: string, apiUrl: string) =>
  new HttpRequestor(fetch, apiUrl, new Cookies({ api_key: apiKey }));

export const fetcherAddressify = () =>
  new HttpRequestor(fetch, publicRuntimeConfig.addressifyUrl, new Cookies({}));

export const fetcherRecaptcha = () =>
  new HttpRequestor(fetch, publicRuntimeConfig.recaptchaUrl, new Cookies({}));

export const fetcherWestpac = (secretKey: string) =>
  new HttpRequestor(
    fetch,
    publicRuntimeConfig.westpacUrl,
    new Cookies({ api_basic_token: secretKey }),
  );

export default HttpRequestor;
